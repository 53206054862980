import revive_payload_client_4sVQNw7RlN from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/chris/Projects/autoz/dealerme/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/chris/Projects/autoz/dealerme/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_ghbUAjaD3n from "/Users/chris/Projects/autoz/dealerme/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import vueformPlugin_kEcARWMiqX from "/Users/chris/Projects/autoz/dealerme/.nuxt/vueformPlugin.mjs";
import plugin_PSbK9A48ZQ from "/Users/chris/Projects/autoz/dealerme/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/Users/chris/Projects/autoz/dealerme/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import session_client_NxDDSatxox from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_tMGwffvjBc from "/Users/chris/Projects/autoz/dealerme/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/chris/Projects/autoz/dealerme/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/chris/Projects/autoz/dealerme/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_OVoKJro5pc from "/Users/chris/Projects/autoz/dealerme/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import _06_init_guZrMuVhRw from "/Users/chris/Projects/autoz/dealerme/plugins/06.init.ts";
import _07_fingerprintAndSnowplow_client_jlBq2pDF72 from "/Users/chris/Projects/autoz/dealerme/plugins/07.fingerprintAndSnowplow.client.ts";
import _08_gtm_client_K93xH8zQNu from "/Users/chris/Projects/autoz/dealerme/plugins/08.gtm.client.ts";
import ascLogging_client_A0TwaNiClj from "/Users/chris/Projects/autoz/dealerme/plugins/ascLogging.client.ts";
import assignUTMParams_LBs5qbPZFj from "/Users/chris/Projects/autoz/dealerme/plugins/assignUTMParams.ts";
import cargurus_client_6GcAPGZybw from "/Users/chris/Projects/autoz/dealerme/plugins/cargurus.client.ts";
import gtag_client_Zw8EQXNVTz from "/Users/chris/Projects/autoz/dealerme/plugins/gtag.client.ts";
import i18n_client_gXssPVzz4q from "/Users/chris/Projects/autoz/dealerme/plugins/i18n.client.ts";
import matamo_client_dVllm8VqAx from "/Users/chris/Projects/autoz/dealerme/plugins/matamo.client.js";
import sentry_client_shVUlIjFLk from "/Users/chris/Projects/autoz/dealerme/plugins/sentry.client.ts";
import storyblok_LEeyKMYv2l from "/Users/chris/Projects/autoz/dealerme/plugins/storyblok.ts";
import toastify_ScRgZgP9op from "/Users/chris/Projects/autoz/dealerme/plugins/toastify.ts";
import userway_client_xwXgf93O8z from "/Users/chris/Projects/autoz/dealerme/plugins/userway.client.ts";
import videoLogging_client_Imf2fykeaR from "/Users/chris/Projects/autoz/dealerme/plugins/videoLogging.client.ts";
import zaraz_client_6CEeXcHlr5 from "/Users/chris/Projects/autoz/dealerme/plugins/zaraz.client.ts";
import plugin_client_QHzhVBB2Ll from "/Users/chris/Projects/autoz/dealerme/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_ghbUAjaD3n,
  vueformPlugin_kEcARWMiqX,
  plugin_PSbK9A48ZQ,
  plugin_Jozdw60ZsE,
  session_client_NxDDSatxox,
  plugin_tMGwffvjBc,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_OVoKJro5pc,
  _06_init_guZrMuVhRw,
  _07_fingerprintAndSnowplow_client_jlBq2pDF72,
  _08_gtm_client_K93xH8zQNu,
  ascLogging_client_A0TwaNiClj,
  assignUTMParams_LBs5qbPZFj,
  cargurus_client_6GcAPGZybw,
  gtag_client_Zw8EQXNVTz,
  i18n_client_gXssPVzz4q,
  matamo_client_dVllm8VqAx,
  sentry_client_shVUlIjFLk,
  storyblok_LEeyKMYv2l,
  toastify_ScRgZgP9op,
  userway_client_xwXgf93O8z,
  videoLogging_client_Imf2fykeaR,
  zaraz_client_6CEeXcHlr5,
  plugin_client_QHzhVBB2Ll
]